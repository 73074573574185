<template>
  <div class="free-to-play-wrapper">
    <div class="game-list-box">
      <div class="top-box">
        <div class="search_box">
          <div>
            <div class="input-search-box">
              <el-autocomplete ref="input" class="inline-input" v-model.trim="searchText" :fetch-suggestions="querySearch"
                :placeholder="placeText" @select="handleSelect" @focus="handleFocus" @blur="handleBlur"
                @keydown.enter.native="handleSearch" popper-class="search-input"></el-autocomplete>
              <!-- :hide-loading="true" -->
              <!-- <template slot-scope="{ item }">
                  <div class="name">{{ item.value }}</div>
              </template>-->
              <!-- :trigger-on-focus="false" -->
              <!-- :highlight-first-item="true" -->
              <!-- :select-when-unmatched="true" -->
            </div>

            <el-button type="primary" @click="handleSearch" icon="el-icon-search"
              style="border-radius: 0 4px 4px 0"></el-button>

            <label class="type">特征类型：</label>
            <el-checkbox v-model="isArchive" @change="typeSelect('isArchive', $event)">支持云存档</el-checkbox>
            <el-checkbox v-model="supportedLanguages"
              @change="typeSelect('supportedLanguages', $event)">支持中文</el-checkbox>
            <el-checkbox v-model="isNewGame" @change="typeSelect('isNewGame', $event)">新游推荐</el-checkbox>
            <el-checkbox v-model="installed" @change="typeSelect('installed', $event)">已安装游戏</el-checkbox>

            <!-- <el-tag
              :class="['tagObj', isArchive ? 'active' : '']"
              @click="typeSelect('isArchive')"
              ></el-tag
            > -->
            <!-- <el-tag
              :class="['tagObj', isNewGame ? 'active' : '']"
              @click="typeSelect('isNewGame')"
              >新游推荐</el-tag
            >

            <el-tag
              :class="['tagObj', installed ? 'active' : '']"
              @click="typeSelect('installed')"
              >已安装游戏</el-tag
            > -->
          </div>

          <!-- <router-link to="/bulletinBoard" class="game-btn">
            新游预告榜
          </router-link> -->
        </div>
        <div class="platform-box">
          <label>标签：</label>
          <span @click="changeLabel('')" :class="['tag', labels == '' ? 'active' : '']">不限</span>
          <span v-for="(item, index) in labelList" :key="index" v-show="index < 7"
            :class="['tag', labels == item ? 'active' : '']" @click="changeLabel(item)">
            {{ item }}
          </span>

          <el-popover placement="bottom" title="全部标签" width="750" trigger="click" popper-class="tag-popper">
            <div class="all-tags">
              <span v-for="(item, index) in labelList" :key="index" :class="['tag', labels == item ? 'active' : '']"
                @click="changeLabel(item)">
                {{ item }}
              </span>
            </div>

            <a slot="reference">
              <i class="el-icon-arrow-down" v-if="labelList.length > 7"></i>
            </a>
          </el-popover>
        </div>

        <div class="platform-box">
          <label>平台：</label>
          <span @click="getPlatformId(0)" :class="['tag', platformId == 0 ? 'active' : '']">不限</span>
          <span @click="getPlatformId(1)" :class="['tag', platformId == 1 ? 'active' : '']">
            <img style="width: 33px; height: 23px" src="@/assets/img/steam.png" />steam
          </span>
          <!-- <span
            @click="getPlatformId(3)"
            :class="['tag', platformId == 3 ? 'active' : '']"
          >
            <img
              style="width: 33px; height: 23px"
              src="@/assets/img/baoxue.png"
            />暴雪
          </span> -->
          <span @click="getPlatformId(5)" :class="['tag', platformId == 5 ? 'active' : '']">
            <img style="width: 33px; height: 23px" src="@/assets/img/epic.png" />epic
          </span>
          <span @click="getPlatformId(2)" :class="['tag', platformId == 2 ? 'active' : '']">
            <img style="width: 33px; height: 23px" src="@/assets/img/Origin.png" />Origin
          </span>
          <span @click="getPlatformId(6)" :class="['tag', platformId == 6 ? 'active' : '']">
            <img style="width: 33px; height: 23px" src="@/assets/img/Uplay.png" />Uplay
          </span>
          <span @click="getPlatformId(4)" :class="['tag', platformId == 4 ? 'active' : '']">
            <img style="width: 33px; height: 23px" src="@/assets/img/Riot.png" />Riot
          </span>
        </div>
      </div>

      <div v-if="gameList.length > 0">
        <div class="game-wrapper">
          <div class="main-box">
            <div class="main-item" v-for="(item, index) in gameList" :key="index">
              <a href="javascript:;" class="link-box" @click="toDetail(item.ProductID)" v-if="item.gameInfoEntity">
                <span class="install" v-if="gamesStr.indexOf(item.gameInfoEntity.SteamGameId) > -1">已安装</span>
                <img :src="`${item.gameInfoEntity.PicList[0]}?x-oss-process=image/resize,h_100`" class="game-img" alt />
                <div class="tags">
                  <span v-if="item.gameInfoEntity.SupportedLanguages.includes('中文')">支持中文</span>
                  <span v-if="item.gameInfoEntity.Type == 2 || item.gameInfoEntity.Type == 3">支持存档</span>
                </div>
                <div class="item-wrapper">
                  <div class="item-box">
                    <!-- <h4 v-if="item.gameInfoEntity.Type == 2 ||
                      item.gameInfoEntity.Type == 3
                      ">
                      {{ beautySub(item.gameInfoEntity.Name, 4) }}
                      <span class="cloud-archiving">支持存档</span>
                    </h4> -->
                    <h4>{{ item.gameInfoEntity.Name }}</h4>
                    <div class="text-hidden">
                      <div class="tag-box">
                        <el-tag v-for="(i, index) in item.gameInfoEntity.Labels.split(
                          ','
                        )" :key="index">{{ i }}</el-tag>
                      </div>
                    </div>
                    <router-link :to="`/gameDetail/${item.ProductID}`" class="btn-experience">{{ netBarVIP ? "免费体验" :
                      "马上体验" }}</router-link>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <!-- 分页 -->
        <div class="pagination-box">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageIndex" :page-size="pageSize" layout="prev, pager, next, jumper"
            :total="total"></el-pagination>
          <!--sizes,  :page-sizes="[8, 16, 32, 64]" -->
        </div>
      </div>

      <div v-if="nodata" class="noData">
        <!-- <a href="javascript:;" @click="goBack">暂无数据，点我返回</a> -->
        <div class="no-data">
          <img src="@/assets/img/noData.png" alt />
          <p>抱歉，没有找到您想要的游戏</p>
        </div>

        <div class="rec-box" v-if="recommendList.length">
          <div>其他人都在玩</div>
          <div class="game-wrapper">
            <div class="main-box">
              <div class="main-item" v-for="(item, index) in recommendList" :key="index">
                <a href="javascript:;" class="link-box" @click="toDetail(item.ProductID)"
                  v-if="item && item.gameInfoEntity">
                  <img :src="`${item.gameInfoEntity.PicList[0]}?x-oss-process=image/resize,h_100`" class="game-img" alt />
                  <div class="item-wrapper">
                    <div class="item-box">
                      <h4 v-if="item.gameInfoEntity.Type == 2 ||
                        item.gameInfoEntity.Type == 3
                        ">
                        {{ beautySub(item.gameInfoEntity.Name, 4) }}
                        <span class="cloud-archiving">支持存档</span>
                      </h4>
                      <h4 v-else>{{ item.gameInfoEntity.Name }}</h4>
                      <div class="text-hidden">
                        <div class="tag-box">
                          <el-tag v-for="(
                              i, index
                            ) in item.gameInfoEntity.Labels.split(',')" :key="index">{{ i }}</el-tag>
                        </div>
                      </div>
                      <router-link :to="`/gameDetail/${item.ProductID}`" class="btn-experience">{{ netBarVIP ? "免费体验" :
                        "马上体验" }}</router-link>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GetGameList,
  GetHomePageGames,
  GetGameLabels,
  GetGameListNew,
  PostGameListNew
} from "@/api";

// 节流函数
const delay = (function () {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

import { myMixins } from "@/util/mixins.js";

export default {
  mixins: [myMixins],
  name: "FreeToPlay",
  computed: {
    ...mapGetters(["isLogin", "netBarId", "netBarName", "netBarVIP"]),
  },
  data() {
    return {
      gameList: [],
      nodata: false,
      searchText: "",
      pageIndex: 1,
      pageSize: 15,
      total: 0,
      labels: "",
      labelActive: -1,
      tagList: [],
      platformId: 0,
      restaurants: [],
      historyGame: [],
      searchGame: [],
      recommendList: [],
      placeText: "请输入您要搜索的内容",
      labelList: [],
      isArchive: false,
      supportedLanguages: false,
      isNewGame: false,
      installed: false,
      gamesStr: [],
    };
  },
  created() {
    if (localStorage.getItem("gamesStr")) {
      let str = localStorage.getItem("gamesStr");
      str = str.substr(0, str.length - 1);
      this.gamesStr = str.split(",");
    }
    if (this.$route.query.isArchive) {
      this.isArchive = true;
    }
    if (this.$route.query.isNewGame) {
      this.isNewGame = true;
    }
    if (this.$route.query.installed) {
      this.installed = true;
    }
  },
  mounted() {
    // 获取全部游戏
    this._GetGameList();
    // 获取历史搜索
    // this._GetHistoryGame();

    if (this.$route.query.name) {
      this.searchText = this.$route.query.name;
      this.$refs.input.focus();
      this.page = 1;
      this._GetGameSearText();

      //   // this.labels = "";
      //   // this.labelActive = -1;
      //   this.$refs.input.focus();
      //   // 获取游戏列表
      //   // this._GetGameList();
    }

    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
      this.labels = "";
      this.labelActive = -1;
      this.$refs.input.focus();
    }
    eventBus.$on("gameList", (name) => {
      this.page = 1;
      this.searchText = name;
      this._GetGameList();
    });

    this._GetGameLabels();
  },
  methods: {
    // 获取游戏列表标签
    async _GetGameLabels() {
      let res = await GetGameLabels();
      if (res.StatusCode == 200) {
        this.labelList = res.Object;
      }
    },
    // 获取历史搜索
    _GetHistoryGame(cb) {
      if (localStorage.getItem("search_cache")) {
        this.historyGame = JSON.parse(localStorage.getItem("search_cache"));
        let arr = [],
          obj = {};
        this.historyGame.map((item, index) => {
          obj = {};
          obj.value = item;
          obj.key = index;
          arr.push(obj);
        });
        this.restaurants = arr;
      } else {
        this.restaurants = [];
      }
      cb && cb();
    },
    // 获取游戏搜索
    async _GetGameSearText(cb) {
      let params = {
        keyword: this.searchText,
        pageSize: 999,
        pageIndex: this.pageIndex,
        labels: this.labels,
        platformId: this.platformId,
      };
      let res = await GetGameList(params);

      if (res.StatusCode == 200) {
        this.searchGame = res.Items;
        if (this.searchGame.length) {
          let arr = [],
            obj = {};
          this.searchGame.map((item, index) => {
            obj = {};
            obj.value = item.gameInfoEntity.Name;
            obj.key = item.ProductID;
            arr.push(obj);
          });
          this.restaurants = arr;
        } else {
          this.restaurants = [];
        }
        cb && cb();
      }
    },
    querySearch(queryString, cb) {
      if (this.searchText) {
        this._GetGameSearText(() => {
          var restaurants = this.restaurants;
          var results = queryString
            ? restaurants.filter(this.createFilter(queryString))
            : restaurants;
          // 调用 callback 返回建议列表的数据
          cb(results);
        });
      } else {
        this.pageIndex = 1;
        this._GetGameList();
        this._GetHistoryGame(() => {
          var restaurants = this.restaurants;

          var results = queryString
            ? restaurants.filter(this.createFilter(queryString))
            : restaurants;
          // 调用 callback 返回建议列表的数据
          cb(results);
        });
      }
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          // restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          // 0
          restaurant.value.indexOf(queryString) > -1 || restaurant.value
        );
      };
    },
    handleSelect(item) {
      this.searchText = item.value;

      if (item.key && item.key.toString().indexOf("PC") > -1) {
        if (item.key == "PC220217000001") {
          this.$router.push("/boutique");
        } else {
          this.$router.push(`/gameDetail/${item.key}`);
        }
      } else {
        this.handleSearch();
      }
    },
    handleFocus() {
      this.placeText = "";
    },
    handleBlur() {
      this.placeText = "请输入您要搜索的内容";
    },
    handleSearch() {
      this.localStorageData();
      this.pageIndex = 1;
      this._GetGameList();
    },
    localStorageData() {
      if (this.searchText) {
        if (localStorage.getItem("search_cache")) {
          let list = JSON.parse(localStorage.getItem("search_cache"));
          for (let i in list) {
            //循环遍历
            if (list[i] == this.searchText) {
              //如果缓存数组中有搜索关键词
              list.splice(i, 1); //删除数组中的该关键词
            }
          }
          list.unshift(this.searchText); //将搜索关键词添加到数组开头
          list.splice(10); //只保留10个
          this.historyGame = list;
        } else {
          this.historyGame = [];
          this.historyGame.push(this.searchText);
        }

        // 保存
        localStorage.setItem("search_cache", JSON.stringify(this.historyGame));
      }
    },
    changeLabel(label) {
      this.searchText = "";
      this.nodata = false;
      this.labels = label;
      this.pageIndex = 1;
      this._GetGameList();
    },
    getPlatformId(index) {
      this.searchText = "";
      this.nodata = false;
      this.platformId = index;
      this.pageIndex = 1;
      this._GetGameList();
    },
    toDetail(productId) {
      this.searchText = "";
      if (productId == "PC220217000001") {
        this.$router.push("/boutique");
      } else {
        this.$router.push(`/gameDetail/${productId}`);
      }
    },
    goBack() {
      this.searchText = "";
      this.pageIndex = 1;
      this.labels = "";
      this._GetGameList();
    },
    typeSelect(str, e) {
      if (str == "isArchive") {
        this.isArchive = e;
      }
      if (str == "isNewGame") {
        this.isNewGame = e;
      }
      if (str == "installed") {
        this.installed = e;
      }
      if (str == "supportedLanguages") {
        this.supportedLanguages = e;
      }

      this.pageIndex = 1;
      this._GetGameList();
    },
    tagsSelect(name, index) {
      this.labels = name;
      if (index != this.labelActive) {
        this.labelActive = index;
        this.pageIndex = 1;
      } else {
        this.labels = "";
        this.labelActive = -1;
      }
      this._GetGameList();
    },
    // 一页展示多少条
    handleSizeChange(val) {
      this.pageIndex = 1;
      this.pageSize = val;
      this._GetGameList();
    },
    // 分页
    handleCurrentChange(val) {
      this.pageIndex = val;
      this._GetGameList();
      _czc.push(["_trackEvent", "免费玩页面", "分页点击", "统计量"]);
    },
    // 获取游戏列表
    async _GetGameList() {
      let params = {
        source: 3,
        keyword: this.searchText,
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        labels: this.labels,
        platformId: this.platformId || "",
      };

      if (this.isArchive) {
        params.isArchive = this.isArchive;
      }
      if (this.supportedLanguages) {
        params.supportedLanguages = this.supportedLanguages ? "中文" : "";
      }
      if (this.isNewGame) {
        params.isNewGame = this.isNewGame;
      }
      if (this.installed) {
        if (localStorage.getItem("gamesStr")) {
          let str = localStorage.getItem("gamesStr");
          params.installed = str.substr(0, str.length - 1);
        }
      }
      // let res = await GetGameListNew(params);
      let res = await PostGameListNew(params);
      console.log("res", res)
      
      if (res.StatusCode == 200) {
        this.gameList = [];
        this.gameList = res.Items;
        if (
          this.gameList.length &&
          this.labels == "" &&
          this.searchText == ""
        ) {
          this.gameList.map((item, index) => {
            if (index < 4 && this.pageIndex == 1) {
              item.new = true;
            }
          });
        }
        this.total = res.Total;

        if (this.gameList.length == 0) {
          this.nodata = true;
          // 推荐游戏
          this._GetHomePageGames(1);
        } else {
          this.nodata = false;
        }
      }
    },
    //获取平台、网吧用户和游戏数据
    async _GetHomePageGames(type) {
      let params = {
        type: type,
        navigationType: 8,
      };
      let res = await GetHomePageGames(params);
      if (res.StatusCode == 200) {
        let data = res.Object;
        this.recommendList = data.slice(0, 8);
      }
    },
    beautySub(str, len) {
      var reg = /[\u4e00-\u9fa5]/g, //专业匹配中文
        slice = str.substring(0, len),
        chineseCharNum = ~~(slice.match(reg) && slice.match(reg).length),
        realen = slice.length * 2 - chineseCharNum;
      return str.substr(0, realen) + (realen < str.length ? "..." : "");
    },
  },
  watch: {
    //监听搜索，300ms执行一次查询方法（去搜索）
    // searchText() {
    //   delay(() => {
    //     this.localStorageData();
    //     console.log(this.searchText);
    //     // if (this.searchText) {
    //     //   this.pageIndex = 1;
    //     //   this.pageSize = 999;
    //     //   this._GetGameSearText();
    //     // } else {
    //     //   console.log("没有");
    //     //   // this.restaurants = [];
    //     //   this._GetHistoryGame();
    //     // }
    //   }, 300);
    // },
    // $route(to, from) {
    //   if (this.$route.query.name) {
    //     console.log(this.$route.query.name);
    //     this.searchText = this.$route.query.name;
    //     this.pageIndex = 1;
    //     this.labels = "";
    //     this.labelActive = -1;
    //     this.$refs.input.focus();
    //     // 获取游戏列表
    //     this._GetGameList();
    //   }
    //   if (this.$route.query.page) {
    //     this.pageIndex = parseInt(this.$route.query.page);
    //     this.labels = "";
    //     this.labelActive = -1;
    //     this.$refs.input.focus();
    //     // 获取游戏列表
    //     this._GetGameList();
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.free-to-play-wrapper {
  height: 100%;
  overflow: hidden;

  .game-list-box {
    padding: 12px 20px;
    width: 1040px;
    // height: 612px;
    margin: 0 auto;

    .top-box {
      border-radius: 6px;
      padding: 10px 20px;
      @include item_bg_col();

      .search_box {
        // padding: 0 20px;
        // height: 60px;
        display: flex;
        justify-content: space-between;

        .type {
          margin-left: 50px;
          @include font_col6();
        }

        .input-search-box {
          display: inline-block;
          position: relative;

          // height: 42px;
          .his-text {
            width: 100%;
            max-height: 360px;
            overflow: auto;
            position: absolute;
            top: 40px;
            left: 0;
            background: #fff;
            border-top: none;
            box-sizing: border-box;
            z-index: 999;

            .item {
              height: 28px;
              line-height: 28px;
              padding: 0 10px;

              &:hover {
                cursor: pointer;
                background: #f0f0f0;
              }
            }

            .clear-his {
              height: 30px;
              line-height: 30px;
              padding-right: 10px;
              font-size: 12px;
              text-align: right;
              color: #666;

              &:hover {
                cursor: pointer;
              }
            }

            &.bd-red {
              // border: 1px solid $red-col;
              border-top: none;
            }
          }
        }

        .el-input {
          width: 260px;
          display: inline-block;
          // margin-top: 10px;
        }

        ::v-deep .el-input__inner {
          // height: 42px;
          line-height: 42px;
          // border: none;
          border-radius: 0;
          @include font_col9();
          @include tag_bg_col();
          // border: 1px solid $red-col;
          @include item_bd_color();
          box-sizing: border-box;
          // background: url("./../assets/images/home/input_bg.png") no-repeat;
          // background-size: 100% 100%;
        }

        ::v-deep .el-input__inner:focus {
          border-color: $red-col !important;
          // @include item_bd_color();
        }

        img {
          margin-top: 5px;

          &:hover {
            transform: translateY(-2px);
          }
        }

        .el-tag {
          @include font_col9();

          &:hover {
            color: $red-col;
            // border: 1px solid $red-col;
          }
        }

        .tagObj {
          // width: 60px;
          margin-left: 20px;
          text-align: center;
          background: transparent;
          color: $bt-linear-col;
          border-color: $bt-linear-col;
          cursor: pointer;

          &.active {
            background: $bt-linear-col;
            color: $white-col;
          }
        }

        .game-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          // margin-top: 10px;
          text-align: center;
          display: block;
          background: $linear-col;
          color: $white-col;
          border-radius: 4px;
        }
      }

      .platform-box {
        display: flex;
        align-items: center;
        margin-top: 10px;
        // padding: 20px;
        @include font_col6();

        label {
          width: 46px;
        }

        .tag {
          width: 100px;
          height: 28px;
          line-height: 28px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include item_bd_color();
          box-sizing: border-box;
          // text-align: center;
          margin: 0 8px;
          cursor: pointer;

          &.active {
            border: 1px solid $red-col;
          }

          &:hover {
            border: 1px solid $red-col;
          }
        }
      }
    }

    .game-wrapper {
      display: flex;

      // height: 580px;
      // overflow: auto;
      .main-box {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        // overflow: hidden;
        .main-item {
          width: 192px;
          height: 160px;
          margin-right: 20px;
          margin-top: 10px;
          position: relative;
          box-sizing: border-box;
          border-radius: 6px;
          overflow: hidden;
          position: relative;

          .install {
            background: #ff3636;
            position: absolute;
            top: 0;
            right: 0;
            color: #fff;
            padding: 5px;
            font-size: 12px;
          }

          .tags {
            width: 100%;
            background: rgba(0, 0, 0, 0.7);
            position: absolute;
            top: 78px;
            left: 0;
            right: 0;
            color: #ff3636;
            padding: 5px;
            font-size: 12px;
            box-sizing: border-box;
            text-align: center;

            span {
              border: 1px solid #ff3636;
              margin: 0 5px;
              padding: 0 5px;
              border-radius: 8px;
            }
          }

          @include item_bg_col();

          .icon-img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 101;
          }

          a {
            width: 192px;
            height: 180px;
            display: block;

            .game-img {
              width: 100%;
              height: 100px;
            }

            .item-wrapper {
              width: 100%;

              .item-box {
                display: flex;
                flex-direction: column;
                padding: 5px 10px;

                h4 {
                  text-align: center;
                  font-size: 16px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  line-height: 20px;
                  @include font_col3();

                  .cloud-archiving {
                    padding: 5px;
                    margin-left: 10px;
                    background: $linear-col;
                    border-radius: 4px;
                    color: $white-col;
                    font-size: 12px;
                  }
                }

                .tag-box {
                  overflow: hidden;
                  margin: 4px 0;
                  height: 18px;
                  text-align: center;

                  .el-tag {
                    margin-right: 5px;
                    background: none !important;
                    height: 18px;
                    line-height: 18px;
                    border-radius: 4px;
                    @include font_col9();
                  }
                }

                .btn-experience {
                  width: 100%;
                  height: 26px;
                  line-height: 26px;
                  display: none;
                  text-align: center;
                  color: $white-col;
                  margin: 5px auto;
                  font-size: 16px;
                  border-radius: 4px;
                  background: $red-col;
                }
              }
            }
          }
        }

        .main-item:nth-child(5n) {
          margin-right: 0;
        }

        .main-item:hover {
          transform: translateY(-5px);
          transition: 0.2s ease;
          box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);

          // border: 2px solid $red-col;
          // .game-img {
          //   width: 188px;
          // }
          h4 {
            color: $red-col;
          }

          .text-hidden {
            display: none;
          }

          // .btn-experience {
          //   background: url("./../assets/images/games/btn-hover.png") no-repeat !important;
          // }
          .btn-experience {
            display: block !important;
          }
        }
      }
    }

    .pagination-box {
      margin-top: 12px;
      text-align: center;
    }
  }

  .noData {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 20px auto;

    .no-data {
      padding: 20px 0;
      border-radius: 8px;
      text-align: center;
      @include item_bg_col();

      img {
        width: 140px;
      }

      p {
        font-size: 16px;
        margin-top: 30px;
        @include font_col6();
      }
    }

    .rec-box {
      margin-top: 10px;
    }

    // a {
    //   padding: 10px 25px;
    //   border-radius: 4px;
    //   font-size: 16px;
    //   color: $bt-linear-col;
    //   border: 1px solid $bt-linear-col;
    //   @include font_col6;
    // }
  }
}
</style>
<style lang="scss">
.tag-popper {
  @include item_bg_col();

  .el-popover__title {
    padding: 10px;
    margin-bottom: 0px !important;
    @include font_col6();
    @include tag_bg_col();
  }

  .all-tags {
    padding: 10px;

    .tag {
      height: 28px;
      line-height: 28px;
      display: inline-block;
      text-align: center;
      border-radius: 4px;
      @include item_bd_color();
      box-sizing: border-box;
      margin: 5px 8px;
      padding: 0 10px;
      cursor: pointer;

      &.active {
        border: 1px solid $red-col;
      }

      &:hover {
        border: 1px solid $red-col;
      }
    }
  }
}

.el-card {
  border: none;
}

.el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: transparent;
}

.el-card__body {
  padding: 0 !important;
}

// .el-autocomplete-suggestion {
//   @include item_bd_color();
// }
// .el-popper[x-placement^="bottom"] .popper__arrow {
//   @include item_bd_color();
// }
[data-theme="light"] .free-to-play-wrapper .game-list-box .top-box .search_box[data-v-fae5bece] .el-input__inner {
  @include font_col6();
}

.el-autocomplete-suggestion__wrap {
  padding: 0;
  max-height: 360px;
}

.el-autocomplete-suggestion {
  // @include item_bd_color();
  border: 1px solid $red_col;
  @include item_bg_col();
}

.el-autocomplete-suggestion li {
  line-height: 32px;
  @include font_col6();
}

.el-popper[x-placement^="bottom"] .popper__arrow {
  // @include item_bd_bottom_color();
  border-bottom-color: $red_col;
}

[data-theme="light"] .el-autocomplete-suggestion {
  // @include item_bd_color();
}

.el-autocomplete-suggestion li.highlighted,
.el-autocomplete-suggestion li:hover {
  @include tag_bg_col();
}
</style>
